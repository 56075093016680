export const images = {
  logo: require("./Logo.png"),
  accentGrad: require("./accentCircleGrad.png"),
  accentSolid: require("./accentCircleSolid.png"),
  accentMedium: require("./accentCircleMedium.png"),
  purpleCircleGrad: require("./purpleCircleGrad.png"),
  purpleCircleMedium: require("./purpleCircleMedium.png"),
  purpleCircleSolid: require("./purpleCircleSolid.png"),
  arrowCurve: require("./ArrowCurve.png"),
  arrowStraight: require("./arrowStraight.png"),
  blueCircleGrad: require("./blueCircleGrad.png"),
  blueCircleMedium: require("./blueCircleMedium.png"),
  blueCircleSolid: require("./blueCircleSolid.png"),
  bulb: require("./bulbImage.png"),
  clients: require("./clients.png"),
  mail: require("./mailImage.png"),
  net: require("./net.png"),
  star: require("./Star3.png"),
  textRectangle: require("./textRectangle.png"),
  user: require("./user.png"),
  ring: require("./circle.png"),
  net2: require("./net2.png"),
  net3: require("./net3.png"),
  submit: require("./submitIcon.png"),
  ai: require("./ai.png"),
  bi: require("./bi.png"),
  cloud: require("./cloud.png"),
  web: require("./web.png"),
  nike: require("./nike.png"),
  whirlpool: require("./whirlpool.png"),
  handy: require("./handy.png"),
  rak: require("./rak.png"),
  pfizer: require("./pfizer.png"),
  unknownClient: require("./unknownClient.png"),
  blog: require("./blog.jpg"),
  blueprints: [
    require("./first.png"),
    require("./second.png"),
    require("./third.png"),
    require("./forth.png"),
  ],
};
