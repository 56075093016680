import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { baseUrl, routeUrl } from "../utils/links";
import CardSkeleton from "../components/skeletons/CardSkeleton";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const CaseStudy = () => {
  const navigate = useNavigate();
  const { data: allCaseStudies, isLoading } = useQuery({
    queryKey: ["case-studies"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(`${routeUrl}/case-study`, {
          withCredentials: true,
        });
        return data;
      } catch (error) {
        console.log(error);
      }
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [allCaseStudies]);

  return (
    <div className="min-h-screen w-full bg-white mt-14">
      <>
        {isLoading ? (
          <>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 sm:gap-4 lg:gap-6 xl:gap-8">
            {allCaseStudies?.map((casestudy, i) => (
              <div
                onClick={() => navigate(`/blog/${casestudy?._id}`)}
                className="w-full h-[250px] lg:min-h-[350px] flex flex-col gap-1 lg:gap-2 xl:gap-3 items-center justify-start text-left rounded-xl p-3 lg:p-4 shadow-custom bg-white cursor-pointer"
                key={i}
              >
                <img
                  src={`${baseUrl}/${casestudy?.imageUrl}`}
                  alt="blog"
                  className="w-full h-[60%] rounded-lg"
                />
                <p className="w-full font-bold sm:text-[14px] md:text-[17px] lg:text-xl leading-5 mt-3 sm:mt-1 md:mt-2 lg:mt-3 lg:leading-[120%]">
                  {casestudy?.title}
                </p>
                <p className="line-clamp-2 text-xs md:text-sm">
                  {casestudy?.subTitle}
                </p>
              </div>
            ))}
            {allCaseStudies?.map((casestudy, i) => (
              <div
                onClick={() => navigate(`/blog/${casestudy?._id}`)}
                className="w-full h-[250px] lg:min-h-[350px] flex flex-col gap-1 lg:gap-2 xl:gap-3 items-center justify-start text-left rounded-xl sm:p-3 lg:p-4 shadow-custom bg-white "
              >
                <img
                  src={`${baseUrl}/${casestudy?.imageUrl}`}
                  alt="blog"
                  className="w-full h-[60%] rounded-lg"
                />
                <p className="w-full font-bold sm:text-[14px] md:text-[17px] lg:text-xl leading-5 mt-3 sm:mt-1 md:mt-2 lg:mt-3 lg:leading-[120%]">
                  {casestudy?.title}
                </p>
                <p className="line-clamp-2 text-xs md:text-sm">
                  {casestudy?.subTitle}
                </p>
              </div>
            ))}
            {allCaseStudies?.map((casestudy, i) => (
              <div
                onClick={() => navigate(`/blog/${casestudy?._id}`)}
                className="w-full h-[250px] lg:min-h-[350px] flex flex-col gap-1 lg:gap-2 xl:gap-3 items-center justify-start text-left rounded-xl sm:p-3 lg:p-4 shadow-custom bg-white "
              >
                <img
                  src={`${baseUrl}/${casestudy?.imageUrl}`}
                  alt="blog"
                  className="w-full h-[60%] rounded-lg"
                />
                <p className="w-full font-bold sm:text-[14px] md:text-[17px] lg:text-xl leading-5 mt-3 sm:mt-1 md:mt-2 lg:mt-3 lg:leading-[120%]">
                  {casestudy?.title}
                </p>
                <p className="line-clamp-2 text-xs md:text-sm">
                  {casestudy?.subTitle}
                </p>
              </div>
            ))}
            {allCaseStudies?.map((casestudy, i) => (
              <div
                onClick={() => navigate(`/blog/${casestudy?._id}`)}
                className="w-full h-[250px] lg:min-h-[350px] flex flex-col gap-1 lg:gap-2 xl:gap-3 items-center justify-start text-left rounded-xl sm:p-3 lg:p-4 shadow-custom bg-white "
              >
                <img
                  src={`${baseUrl}/${casestudy?.imageUrl}`}
                  alt="blog"
                  className="w-full h-[60%] rounded-lg"
                />
                <p className="w-full font-bold sm:text-[14px] md:text-[17px] lg:text-xl leading-5 mt-3 sm:mt-1 md:mt-2 lg:mt-3 lg:leading-[120%]">
                  {casestudy?.title}
                </p>
                <p className="line-clamp-2 text-xs md:text-sm">
                  {casestudy?.subTitle}
                </p>
              </div>
            ))}
            {allCaseStudies?.map((casestudy, i) => (
              <div
                onClick={() => navigate(`/blog/${casestudy?._id}`)}
                className="w-full h-[250px] lg:min-h-[350px] flex flex-col gap-1 lg:gap-2 xl:gap-3 items-center justify-start text-left rounded-xl sm:p-3 lg:p-4 shadow-custom bg-white "
              >
                <img
                  src={`${baseUrl}/${casestudy?.imageUrl}`}
                  alt="blog"
                  className="w-full h-[60%] rounded-lg"
                />
                <p className="w-full font-bold sm:text-[14px] md:text-[17px] lg:text-xl leading-5 mt-3 sm:mt-1 md:mt-2 lg:mt-3 lg:leading-[120%]">
                  {casestudy?.title}
                </p>
                <p className="line-clamp-2 text-xs md:text-sm">
                  {casestudy?.subTitle}
                </p>
              </div>
            ))}
            {allCaseStudies?.map((casestudy, i) => (
              <div
                onClick={() => navigate(`/blog/${casestudy?._id}`)}
                className="w-full h-[250px] lg:min-h-[350px] flex flex-col gap-1 lg:gap-2 xl:gap-3 items-center justify-start text-left rounded-xl sm:p-3 lg:p-4 shadow-custom bg-white "
              >
                <img
                  src={`${baseUrl}/${casestudy?.imageUrl}`}
                  alt="blog"
                  className="w-full h-[60%] rounded-lg"
                />
                <p className="w-full font-bold sm:text-[14px] md:text-[17px] lg:text-xl leading-5 mt-3 sm:mt-1 md:mt-2 lg:mt-3 lg:leading-[120%]">
                  {casestudy?.title}
                </p>
                <p className="line-clamp-2 text-xs md:text-sm">
                  {casestudy?.subTitle}
                </p>
              </div>
            ))}
            {allCaseStudies?.map((casestudy, i) => (
              <div
                onClick={() => navigate(`/blog/${casestudy?._id}`)}
                className="w-full h-[250px] lg:min-h-[350px] flex flex-col gap-1 lg:gap-2 xl:gap-3 items-center justify-start text-left rounded-xl sm:p-3 lg:p-4 shadow-custom bg-white "
              >
                <img
                  src={`${baseUrl}/${casestudy?.imageUrl}`}
                  alt="blog"
                  className="w-full h-[60%] rounded-lg"
                />
                <p className="w-full font-bold sm:text-[14px] md:text-[17px] lg:text-xl leading-5 mt-3 sm:mt-1 md:mt-2 lg:mt-3 lg:leading-[120%]">
                  {casestudy?.title}
                </p>
                <p className="line-clamp-2 text-xs md:text-sm">
                  {casestudy?.subTitle}
                </p>
              </div>
            ))}
            {allCaseStudies?.map((casestudy, i) => (
              <div
                onClick={() => navigate(`/blog/${casestudy?._id}`)}
                className="w-full h-[250px] lg:min-h-[350px] flex flex-col gap-1 lg:gap-2 xl:gap-3 items-center justify-start text-left rounded-xl sm:p-3 lg:p-4 shadow-custom bg-white "
              >
                <img
                  src={`${baseUrl}/${casestudy?.imageUrl}`}
                  alt="blog"
                  className="w-full h-[60%] rounded-lg"
                />
                <p className="w-full font-bold sm:text-[14px] md:text-[17px] lg:text-xl leading-5 mt-3 sm:mt-1 md:mt-2 lg:mt-3 lg:leading-[120%]">
                  {casestudy?.title}
                </p>
                <p className="line-clamp-2 text-xs md:text-sm">
                  {casestudy?.subTitle}
                </p>
              </div>
            ))}
          </div>
        )}
      </>
    </div>
  );
};

export default CaseStudy;
