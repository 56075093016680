export const ringVariant = {
  initial: {
    y: -10,
  },
  animate: {
    y: 10,
    transition: {
      duration: 1,
      repeat: Infinity,
      repeatType: "reverse",
      ease: "easeInOut",
    },
  },
};
export const ringVariantRotate = {
  initial: {
    x: -13,
  },
  animate: {
    x: 13,
    transition: {
      duration: 1,
      delay: 0.5,
      repeat: Infinity,
      repeatType: "reverse",
      ease: "easeInOut",
    },
  },
};

export const slidingAnimate = {
  animate: {
    x: ["8%", "-95%"],
    transition: {
      x: {
        duration: 20,
        ease: "linear",
        repeat: Infinity,
      },
    },
  },
};
