import React from "react";
import { images } from "../../assets";
import { motion, useScroll, useTransform } from "framer-motion";
import { slidingAnimate } from "../../utils/animation-variants";

const Blueprint = () => {
  const { scrollYProgress } = useScroll({
    offset: ["start start", "end end"],
  });
  const imgMove = useTransform(scrollYProgress, [0, 1], ["-200%", "200%"]);

  return (
    <>
      <div className="w-full flex flex-col gap-16 items-center justify-center mt-[100px] md:mt-[150px] 2xl:mt-[200px] relative">
        {/* ----background images (gradients,ring, stars etc.)----- */}
        <motion.img
          style={{ x: imgMove }}
          src={images?.blueCircleMedium}
          alt="circle"
          className="z-[-2] absolute size-[20vh] sm:size-[22vw] top-[-5%] md:top-0 left-[-10%] sm:left-[0%] md:left-[-15%] opacity-50"
        />
        <motion.img
          style={{ x: imgMove }}
          src={images?.accentMedium}
          alt="circle"
          className="z-[-2] size-[25vh] md:size-[20vw] top-[5%] md:top-[15%] left-[40%] md:right-[-20%] absolute opacity-20"
        />
        <motion.img
          style={{ x: imgMove }}
          src={images?.accentMedium}
          alt="circle"
          className="z-[-2] size-[25vh] md:size-[20vw] top-[80%] md:top-[90%] right-[-25%] md:right-[-20%] absolute opacity-60"
        />
        {/* --------- */}
        <div className="flex flex-col items-center justify-center text-center w-full">
          <img
            src={images?.net}
            alt="net"
            className="z-[-2] h-[30vh] md:h-[50vh] w-[100vw] md:w-[70vw] top-0 absolute brightness-50 lg:brightness-100"
          />
          <p className="text-[#925fffa2] font-semibold text-xs sm:text-sm md:text-md xl:text-xl">
            Our Working Procedure
          </p>
          <h1 className="text-2xl sm:text-[35px] md:text-[45px] lg:text-[45px] xl:text-[55px] 2xl:text-[65px] font-bold leading-[120%]">
            The Ztrios Blueprint
          </h1>
          <p className="text-[#7950f2de] text-lg sm:text-[25px] md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[45px] md:mt-4 font-bold relative">
            Crafting Success, One Step at a Time
            <img
              src={images?.arrowStraight}
              alt="circle"
              className="top-[85%] right-[-8%] absolute w-[80px] h-[15px] sm:h-[20px] sm:w-[15vw] sm:mt-2 xl:mt-4"
            />
          </p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-3 xl:gap-8 mt-8 sm:mt-10 xl:mt-14 relative">
            <motion.img
              style={{ x: imgMove }}
              src={images?.purpleCircleMedium}
              alt="circle"
              className="z-[-2] top-[40%] md:top-[50%] left-[30%] md:left-[10%] absolute opacity-40 size-[40vw] md:size-[350px]"
            />
            {images?.blueprints?.map((pic, i) => (
              <img
                src={pic}
                alt="blueprints"
                className="rounded-xl shadow-md hover:scale-105 transition-all duration-300"
                key={i}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-4 items-center justify-center mt-[80px] sm:mt-[120px] relative">
        <motion.img
          style={{ x: imgMove }}
          src={images?.purpleCircleMedium}
          alt="circle"
          className="z-[-2] size-[20vw] top-[70%] left-[-400px] absolute opacity-40"
        />
        <div className="text-center">
          <p className="text-[#925fffd9] font-semibold text-xs md:text-md xl:text-xl">
            Our Partners
          </p>
          <h1 className="text-[25px] sm:text-[30px] md:text-[45px] lg:text-[50px] xl:text-[60px] 2xl:text-[60px] font-bold">
            Brands That Believe in Ztrios
          </h1>
        </div>
        {/* <img src={images?.clients} alt="clients" /> */}
        <div className="w-full overflow-hidden relative h-[100px] flex select-none gap-3 md:gap-7 lg:gap-8 after:content[''] after:z-10 after:absolute after:right-0 after:inset-y-0 after:w-[50px] after:bg-gradient-to-r after:from-transparent after:to-white before:content[''] before:z-10 before:absolute before:left-0 before:inset-y-0 before:w-[50px] before:bg-gradient-to-r before:from-white before:to-transparent">
          <motion.div
            variants={slidingAnimate}
            animate="animate"
            className="w-auto flex px-4 items-center gap-6 md:gap-14 whitespace-nowrap shrink-0"
          >
            <img
              src={images?.nike}
              alt="nike"
              className="size-[15vw] md:size-[10vw] lg:size-[8vw]"
            />
            <img
              src={images?.handy}
              alt="handy"
              className="size-[15vw] md:size-[10vw] lg:size-[8vw]"
            />
            <img
              src={images?.whirlpool}
              alt="whirlpool"
              className="size-[15vw] md:size-[10vw] lg:size-[8vw]"
            />
            <img
              src={images?.rak}
              alt="rak"
              className="size-[15vw] md:size-[10vw] lg:size-[8vw]"
            />
            <img
              src={images?.pfizer}
              alt="pfizer"
              className="size-[15vw] md:size-[10vw] lg:size-[8vw]"
            />
            <img
              src={images?.unknownClient}
              alt="unknownClient"
              className="size-[15vw] md:size-[10vw] lg:size-[8vw]"
            />
          </motion.div>
          <motion.div
            variants={slidingAnimate}
            animate="animate"
            className="w-auto flex px-4 items-center gap-6 md:gap-14 whitespace-nowrap shrink-0"
          >
            <img
              src={images?.nike}
              alt="nike"
              className="size-[15vw] md:size-[10vw] lg:size-[8vw]"
            />
            <img
              src={images?.handy}
              alt="handy"
              className="size-[15vw] md:size-[10vw] lg:size-[8vw]"
            />
            <img
              src={images?.whirlpool}
              alt="whirlpool"
              className="size-[15vw] md:size-[10vw] lg:size-[8vw]"
            />
            <img
              src={images?.rak}
              alt="rak"
              className="size-[15vw] md:size-[10vw] lg:size-[8vw]"
            />
            <img
              src={images?.pfizer}
              alt="pfizer"
              className="size-[15vw] md:size-[10vw] lg:size-[8vw]"
            />
            <img
              src={images?.unknownClient}
              alt="unknownClient"
              className="size-[15vw] md:size-[10vw] lg:size-[8vw]"
            />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Blueprint;
