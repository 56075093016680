import React from "react";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { navbarLinks } from "../../utils/links";
import { images } from "../../assets";

const Footer = () => {
  return (
    <div className="w-full grid grid-cols-2 md:grid-cols-5 max-md:space-y-6 items-start mx-auto mt-[200px] p-2 lg:p-6 max-md:pb-20">
      <div className="max-md:col-span-2 flex flex-col gap-4">
        <img src={images?.logo} alt="logo" className="w-[30vw] md:w-[12vw]" />
        <div className="flex sm:flex-col gap-4 max-sm:divide-x-2 items-center justify-between sm:items-start sm:justify-start">
          <div className="max-sm:flex-1 flex gap-3 text-sm xl:text-md">
            <MdEmail className="fill-purple-600" />
            <p className="line-clamp-2 whitespace-pre-wrap">
              contact@ztrios.com
            </p>
          </div>
          <div className="max-sm:flex-1 flex gap-1 sm:gap-3 text-sm xl:text-md max-sm:pl-3">
            <FaPhoneAlt className="fill-purple-600" />
            <p className="max-sm:text-xs">+880 1785-943035</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 text-xs md:text-sm xl:text-lg">
        <p className="text-[20px] lg:text-[30px] 2xl:text-[40px] mb-2">Links</p>
        {navbarLinks?.map((link, i) => (
          <Link to={link.url} key={i}>
            {link.name}
          </Link>
        ))}
      </div>
      <div className="flex flex-col gap-4 text-xs md:text-sm xl:text-lg [&_p:not(:first-child)]:cursor-pointer">
        <p className="text-[20px] lg:text-[30px] 2xl:text-[40px] mb-2">Legal</p>
        <p>Terms of Use</p>
        <p>Privacy Policy</p>
        <p>Cookie Policy</p>
      </div>
      <div className="flex flex-col gap-4 text-xs md:text-sm xl:text-lg [&_p:not(:first-child)]:cursor-pointer">
        <p className="text-[20px] lg:text-[30px] 2xl:text-[40px] mb-2">
          Product
        </p>
        <p>Take Tour</p>
        <p>Live Chat</p>
        <p>Reviews</p>
      </div>
      <div className="flex flex-col gap-4 text-xs md:text-smb-2m xl:text-lg">
        <p className="text-[20px] lg:text-[30px] 2xl:text-[40px] mb-2">
          Newsletter
        </p>
        <p>Stay Up To Date</p>
      </div>
    </div>
  );
};

export default Footer;
