import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { routeUrl } from "../../utils/links";
import CardSkeleton from "../skeletons/CardSkeleton";
import BlogCards from "./BlogCards";
import { signInFailure } from "../../redux/userSlice";
import { useDispatch } from "react-redux";

const AllBlogs = () => {
  const dispatch = useDispatch();
  const { data: allBlogs, isLoading } = useQuery({
    queryKey: ["allBlogs"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(`${routeUrl}/blogs`, {
          withCredentials: true,
        });
        return data;
      } catch (error) {
        if (error?.response?.status === 403) {
          dispatch(signInFailure());
        } else {
          console.log(error);
        }
      }
    },
  });

  return (
    <>{isLoading ? <CardSkeleton /> : <BlogCards allBlogs={allBlogs} />}</>
  );
};

export default AllBlogs;
